import React from "react";
import {
  faReact,
  faCss3,
  faHtml5,
  faSass,
  faJs,
  faNode,
} from "@fortawesome/free-brands-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

function Skills() {
  return (
    <div className="skills">
      <div className="skills-container">
        <div className="title-container">
          <h5 className="skill-title"> Front-end Tech Stack</h5>
        </div>
        <div className="tech-stacks-container">
          <div className="icons">
            <div className="icon-holder">
              <FontAwesomeIcon icon={faReact} className="icon react" />
            </div>
            <div className="icon-holder">
              <FontAwesomeIcon icon={faCss3} className="icon css" />
            </div>
            <div className="icon-holder">
              <FontAwesomeIcon icon={faHtml5} className="icon html" />
            </div>
            <div className="icon-holder">
              <FontAwesomeIcon icon={faSass} className="icon html" />
            </div>
            <div className="icon-holder">
              <FontAwesomeIcon icon={faJs} className="icon html" />
            </div>
            <div className="icon-holder">
              <div className="icon redux "></div>
            </div>
            <div className="icon-holder">
              <div className="icon redux"></div>
            </div>
            <div className="icon-holder">
              <FontAwesomeIcon className="icon gh" />
            </div>
          </div>
          <div className="tech-stack-paragraph">
            <p>
              Creating highly usable and beautiful front-end interfaces excites
              me. Big fan of React and really enjoy the different state
              management systems, though I'm most comfortable with Redux. On the
              UI side, I've built projects with vanilla CSS, Sass, and
              Material-UI. I have deployment experience with Netlify and Vercel.
            </p>
          </div>
        </div>{" "}
        {/* container ends */}
        <div className="title-container">
          <h5 className="skill-title"> Back-end Tech Stack</h5>
        </div>
        <div className="tech-stacks-container">
          <div className="icons">
            <div className="icon-holder">
              <FontAwesomeIcon icon={faNode} className="icon react" />
            </div>

            <div className="icon-holder">
              <div className="icon redux "></div>
            </div>
            <div className="icon-holder">
              <div className="icon redux"></div>
            </div>
            <div className="icon-holder">
              <FontAwesomeIcon className="icon gh" />
            </div>
          </div>
          <div className="tech-stack-paragraph">
            <p>
              I always approach building databases and REST APIs with
              scalability and growth in mind. My database knowledge is
              extensively growing, as well as my deployment experience. I am
              very familiar with Heroku but getting experience with AWS.
            </p>
          </div>
        </div>{" "}
      </div>
    </div>
  );
}

export default Skills;
