import React, { useState } from "react";
import Title from "../Components/Title";
import Skills from "../Components/Skills";
import Portfolio from "../Components/Portfolio";
import Categories from "../Components/Categories";
import MenuItems from "../Components/MenuItems";
import allPortfolios from "../Components/allPortfolios";

// const allCategories = ["All", ...allPortfolios.map((item) => item.category)];

// above would've worked but it repeats data
const allCategories = [
  "All",
  ...new Set(allPortfolios.map((item) => item.category)),
];
console.log(allCategories);

function PortfolioPage() {
  const [categories, setCategories] = useState(allCategories);
  const [menuItems, setMenuItems] = useState(allPortfolios);

  const filter = (category) => {
    if (category === "All") {
      setMenuItems(allPortfolios);
      return;
    }
    const filteredData = allPortfolios.filter((item) => {
      return item.category === category;
    });
    setMenuItems(filteredData);
  };

  return (
    <div className="PortfolioPage">
      <Title title={"My TechStack"} span={"My TechStack"} />
      <Skills />
      <div className="Portfolio-data">
        <Title title={"My Portfolio"} span={"My Portfolio"} />
        <Categories filter={filter} categories={categories} />
        <MenuItems menuItems={menuItems} />
      </div>
    </div>
  );
}

export default PortfolioPage;
