import React from "react";
import allPortfolios from "../Components/allPortfolios";

function MenuItems({ menuItems }) {
  return (
    <div className="portfolios">
      {menuItems.map((item) => {
        return (
          <div className="portfolio" key={item.id}>
            <div className="image-data">
              <img src={item.image} alt="" />
              <ul className="hover-items">
                <li>
                  <a href={item.app}>{item.appIcon}</a>
                  <a href={item.github}>{item.githubIcon}</a>
                </li>
              </ul>
            </div>
            <h5>{item.title}</h5>
            <p>{item.info}</p>
          </div>
        );
      })}
    </div>
  );
}

export default MenuItems;
