import React from "react";
import {
  faMedium,
  faTwitter,
  faLinkedin,
  faGithub,
} from "@fortawesome/free-brands-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Link } from "react-router-dom";

let mediumProfile = "https://medium.com/@rubesworld";
let twitterProfile = "https://twitter.com/Ruben_5_Ramirez";
let linkedInProfile = "https://linkedin.com/in/rubenandresramirez";
let gitHubProfile = "https://github.com/RubesWorld";

function HomePage() {
  return (
    <div className="HomePage">
      <header className="hero">
        <h1 className="hero-text">
          Welcome to <span> Rube'sWorld</span>
        </h1>
        <p className="h-sub-text">
          I'm a software engineer aiming to make the web a better place through
          thoughtful design and reliable code. I'm passionate about bringing the
          best out of people and creating a comfortable work environment. Click
          the links below to get a bit more insight on who I am as a human,
          software engineer and thinker.
          <br />
          <span>
            I am currently improving my React.js and Node.js skills while
            working on Data Structures and Algorithms.
          </span>
        </p>
        <div className="icons">
          <a
            href={mediumProfile}
            target="_blank"
            rel="noopener noreferrer"
            className="icon-holder"
          >
            <FontAwesomeIcon icon={faMedium} className="icon me" />
          </a>
          <a
            href={twitterProfile}
            target="_blank"
            rel="noopener noreferrer"
            className="icon-holder"
          >
            <FontAwesomeIcon icon={faTwitter} className="icon tw" />
          </a>
          <a
            href={linkedInProfile}
            target="_blank"
            rel="noopener noreferrer"
            className="icon-holder"
          >
            <FontAwesomeIcon icon={faLinkedin} className="icon li" />
          </a>
          <a
            href={gitHubProfile}
            target="_blank"
            rel="noopener noreferrer"
            className="icon-holder"
          >
            <FontAwesomeIcon icon={faGithub} className="icon gh" />
          </a>
        </div>
      </header>
    </div>
  );
}

export default HomePage;
