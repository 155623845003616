import React, { useState } from "react";
import aboutImage from "../img/About.png";
import AboutShort from "./AboutShort";
import AboutLong from "./AboutLong";

function ImageSection() {
  const [about, setAbout] = useState("short");

  const resumeClick = () => {
    window.open(
      "https://drive.google.com/file/d/1csGhGLqz6NUbIrmcf3n_NPxMSwrtKf-b/view?usp=sharing"
    );
  };

  const shortOnClick = () => {
    setAbout("short");
  };

  const longOnClick = () => {
    setAbout("long");
  };

  let bio;
  if (about === "short") {
    bio = <AboutShort />;
  } else {
    bio = <AboutLong />;
  }

  return (
    <div className="ImageSection">
      <div className="image">
        <img src={aboutImage} alt="" />
      </div>
      <div className="toggler">
        <button
          className="short"
          id={about === "short" ? "selected" : ""}
          onClick={shortOnClick}
        >
          Short
        </button>
        <button
          className="long"
          id={about === "long" ? "selected" : ""}
          onClick={longOnClick}
        >
          Long
        </button>
      </div>
      <div>{bio}</div>

      <div className="about-details">
        <button className="btn" onClick={resumeClick}>
          View Resume
        </button>
      </div>
    </div>
  );
}

export default ImageSection;
