import React from "react";

function AboutLong() {
  return (
    <div className="about-info">
      <h4>
        Hi, I'm<span> Ruben A. Ramirez.</span>
      </h4>
      <br></br>
      <p>
        I'm a <span>Mexican-American Software Engineer</span> born and raised in
        the <span>most diverse city in America</span>, Stockton, California.
        With a Finance background, I took a step into tech via Sales, with my
        first taste being as a Sales Intern at Badger Maps, a SaaS company in
        San Francisco.
      </p>
      <br />
      <p>
        {" "}
        Hungry for adventure, I moved to Barcelona, Spain and fell into the
        awesomeness of remote work. I was living in Spain, working for an Indian
        software engineering firm, and selling to American companies (beauty of
        globalization, huh?). After selling engineering services and learning
        about amazing companies building awesome things, a constant feeling of
        longing crept over me:{" "}
        <span>
          I wanted to be on the other side creating awesome products.
        </span>{" "}
        The decision to become a software engineer aligned perfectly with my
        goals and I chose Lambda School's full-stack program. Between my
        business and product skills, and now my engineering skills, I am now
        equipped with the skills to make an impact at a technology company.{" "}
        <span>It's my turn to build.</span>
      </p>
    </div>
  );
}

export default AboutLong;
