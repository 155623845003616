import React from "react";
import Title from "../Components/Title";
import ContactItem from "../Components/contactitem";
import phone from "../img/phone.svg";
import email from "../img/emailme.svg";
import location from "../img/location.svg";

function ContactPage() {
  return (
    <div>
      <div>
        <Title title={"Contact"} span={"Contact"} />
      </div>

      <div className="ContactPage">
        <div className="map-sect">
          <iframe
            src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d71177.81077662708!2d-121.34242064471616!3d37.96208495507704!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x80900d737b442181%3A0x5876f5d1044fcbd8!2sStockton%2C%20CA!5e0!3m2!1sen!2sus!4v1615263389463!5m2!1sen!2sus"
            width="600"
            height="450"
            style={{ border: 0 }}
            allowFullScreen=""
            loading="lazy"
          ></iframe>
        </div>

        <div className="contact-sect">
          <ContactItem
            icon={phone}
            text1={`+1(209) 915-8254`}
            title={"Phone"}
          />
          <ContactItem
            icon={email}
            text1={`hi@rubesworld.live`}
            title={"Email"}
          />
          <ContactItem
            icon={location}
            text1={`Stockton, California, but willing to relocate globally`}
            title={"Location"}
          />
        </div>
      </div>
    </div>
  );
}

export default ContactPage;
