import port1 from "../img/key.jpg";
import GitHub from "../img/github.png";

const allPortfolios = [
  {
    id: 1,
    title: "Anywhere Fitness",
    category: "Full-Stack",
    app: "https://anywhere-fitness-front-end.vercel.app",
    github: "https://github.com/Lambda-Anywhere-Fitness-BW/front-end",
    githubIcon: "GitHub",
    appIcon: "App",
    image: port1,
    info:
      "Anywhere Fitness is an event application where instructors can post their classes and clients can sign-up. This project consisted of 4 total teammates, a back-end developer, one junior React Developer and two Mid-Level React Developer and the timeline for the MVP of this project was one-week, but continued development has occurred to add features.",
  },
  {
    id: 2,
    category: "Back-End",
    title: "Water My Plants",
    app: "https://water-my-plants-orcin.vercel.app",
    github:
      "https://github.com/water-my-plant-tt44/TT-44-water-my-plants-back-end",
    githubIcon: "GitHub",
    appIcon: "App",
    image: port1,
    info:
      "Water My Plants is a web app to help plant owners keep track of their plants and watering schedules. My role in this project was the back-end developer. I designed the database schema, implemented auth via JsonWebTokens and created the needed endpoints from scratch. This project was hosted on Heroku using a PostgreSQL database. ",
  },
  {
    id: 3,
    category: "Full-Stack",
    title: "Big League Socials",
    app: "https://bigleaguesocials-rubesworld.vercel.app",
    github: "https://github.com/RubesWorld/bigleaguesocials",
    githubIcon: "Github",
    appIcon: "App",
    image: port1,
    info:
      "Big League Socials is a website where users can easily find their favorite NBA Athletes social media accounts. Searching every single Twitter profile can become tedious, so this app makes life easier. Built as a solo project and will continue to be developed, focused on expanding to different sports and including the Twitter API. ",
  },
  {
    id: 4,
    category: "Front-End",
    title: "Family Promise Service Tracker",
    app:
      "https://rubesworld.medium.com/a-strong-foundation-for-a-non-profit-application-50f116b54b48",
    github:
      "https://github.com/Lambda-School-Labs/family-promise-service-tracker-fe-a",
    githubIcon: "Github",
    appIcon: "Article",
    image: port1,
    info:
      "A greenfield project for a non-profit fighting homeless across multiple age groups. We received a brief scope of work and had creative freedom on the project. I was the UX Design Lead and front-end developer on a team of 6. We used React.js, Redux, ANT Design, Node and Postgres. ",
  },
];

export default allPortfolios;
