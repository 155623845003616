import React from "react";

function AboutShort() {
  return (
    <div className="about-info">
      <h4>
        Hi, I'm<span> Ruben A. Ramirez.</span>
      </h4>
      <br></br>
      <p>
        {" "}
        Business Development turned Software Engineer. <span>I code</span>, practice intermittent fasting and lift weights. My Mom said I'd talk to a
        tree if they could talk. She was right.
      </p>
    </div>
  );
}

export default AboutShort;
