import React from "react";
import Title from "../Components/Title";
import ImageSection from "../Components/ImageSection";
import Skills from "../Components/Skills";

function AboutPage() {
  return (
    <div className="AboutPage">
      <Title title={"About Me"} span={"About Me"} />
      <ImageSection />
    </div>
  );
}

export default AboutPage;
