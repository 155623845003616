import Lambda from "../img/Lambda.jpg";

// import blog1 from "";
// import blog1 from "";
// import blog1 from "";
// import blog1 from "";

const blogs = [
  {
    id: 3,
    category: "Lambda School",
    image: Lambda,
    title: "Lambda School Labs Project",
    link: "Lambda School",
    date: "29",
    month: "April",
    year: "2021",
  },
  {
    id: 2,
    category: "Lambda School",
    image: Lambda,
    title: "Lambda School Journey #2",
    link: "https://rubesworld.medium.com/lambda-school-journey-2-710bd6a53c74",
    date: "02",
    month: "April",
    year: "2021",
  },
  {
    id: 1,
    category: "Lambda School",
    image: Lambda,
    title: "Lambda School Journey #1",
    link: "https://rubesworld.medium.com/lambda-school-journey-1-f4c41b029e6f",
    date: "18",
    month: "November",
    year: "2021",
  },
  // {
  //   id: 3,
  //   category: "Back-End",
  //   image: blog1,
  //   title: "Node.Js For Newbies",
  //   link: "https://rubesworld.medium.com",
  //   date: "4",
  //   month: "April",
  //   year: "2021",
  // },
];

export default blogs;
